import React from "react"
import Card from "react-bootstrap/Card"
import { Link } from "gatsby"
const Video = ({ title, excerpt, slugURL, featuredImageURL }) => (
  <div class="col-md-6 col-lg-3 col-xl-3 mb-4">
    <Card className="cardShadow">
      <Card.Img variant="top" src={featuredImageURL} />
      <Card.Body>
        <Card.Title className="two-line-clamp">{title}</Card.Title>
        <Card.Text className="four-line-clamp">{excerpt}</Card.Text>
      </Card.Body>
      <Card.Body>
        <Link className="btn btn-primary" to={"/blogs/" + slugURL}>
          Read more
        </Link>
      </Card.Body>
    </Card>
  </div>
)
export default Video
